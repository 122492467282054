body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav {
  height: 100;
  background-color: black;
  width: 100vw;
}

.nav img {
  display: flex;
  justify-content: left;
}

.homeheader {
  display: flex;
  justify-content: center;
  width: 100vw;        
  margin: 0;  
  padding: 0;
}

.homeheader img {
  width: 100%; 
  height: auto;
}

.slot-games {
  padding: 20px;
}

.games-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.game-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  width: 150px;
}

.game-card img {
  width: 100%;
  height: auto;
}

